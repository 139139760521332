import Benefits from "../../components/Benefits"
import { scrollToSection } from "../../components/Navbar"


export const Home = () =>{
  return (
    <>
    <section className="hero mt-12" id='home'>
        <div className='content-dashboard'>
        <div className="hero-content">
          <h1>Engage Your Fans and Generate Income with Collectible Plushies</h1>
          <p>Plush.fun helps content creators to design, crowdfund, manufacture and deliver collectible custom plush toys with one of the market's best revenue sharing model and at no cost for content creators</p>
          <div className="mt-12 text-end">
        <button
          className="px-8 py-4 bg-[#410AF3] text-white font-bold rounded-full text-lg transform transition-all duration-300 hover:bg-blue-700 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          aria-label="Get started with our platform"
          onClick={()=>scrollToSection('#contact')}
        >
          Let's do it!
        </button>
      </div>
        </div>
        <div className="hero-image">
          <img src='img/mainimg.png' style={{width: "650px"}}/>
        </div>
        </div>
      </section>
      
      <Benefits />
      </>
  )
}