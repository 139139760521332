import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { useLocation } from "react-router-dom";
export const scrollToSection = (elementId) => {
  const element = document.getElementById(elementId.replace("#", ""));
  if (element) {
    const headerOffset = 40;
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
    window.history.pushState(null, "", elementId);
  }
};
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

 

  // Check URL to determine which links to display
  const isHomePage = location.pathname === "/";
  const isDemoPage = location.pathname.startsWith("/demo/");

  const navLinks = [
    { title: "Home", path: "#home" },
    ...(isDemoPage ? [{ title: "Demo", path: "#demo" }] : []),
    ...(isHomePage ? [{ title: "Benefits", path: "#benefits" }] : []),
    { title: "How It Works", path: "#how-it-works" },
    { title: "FAQ", path: "#faq" },
    { title: "Contact", path: "#contact" },
   
  ];

  return (
    <nav className="fixed w-full top-0 z-50">
      <div className="bg-gradient-to-r from-[#812BE2] via-[#AB40BD] to-[#DE8B6F] px-4 sm:px-6 lg:px-8 py-4 shadow-lg">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <button
              onClick={() => scrollToSection("#home")}
              className="text-white font-bold text-2xl rounded-lg px-3 py-2 hover:bg-white/10 transition-all duration-300"
              aria-label="plush.fun home"
            >
              plush.fun
            </button>
          </div>

          <div className="hidden md:flex space-x-6">
            {navLinks.map((link, index) => (
              <button
                key={index}
                onClick={() => scrollToSection(link.path)}
                className="text-white hover:bg-white/10 px-3 py-2 rounded-lg transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-white/50"
                aria-label={`Navigate to ${link.title}`}
              >
                {link.title}
              </button>
            ))}
          </div>

          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="text-white p-2 rounded-lg hover:bg-white/10 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-white/50"
              aria-label="Toggle navigation menu"
            >
              {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {isOpen && (
          <div className="md:hidden mt-4 pb-4">
            <div className="flex flex-col space-y-2">
              {navLinks.map((link, index) => (
                <button
                  key={index}
                  onClick={() => {
                    scrollToSection(link.path);
                    setIsOpen(false);
                  }}
                  className="text-white hover:bg-white/10 px-3 py-2 rounded-lg transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-white/50"
                  aria-label={`Navigate to ${link.title}`}
                >
                  {link.title}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
