import React, { useState, useEffect } from "react";
import { FiClock } from "react-icons/fi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const ProductPage = ({ product }) => {
  // Destructure `product` properly
  const [activeTab, setActiveTab] = useState("creator");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Timer setup for countdown (20 days in seconds)
  const [timeLeft, setTimeLeft] = useState(20 * 24 * 60 * 60);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
    if (timeLeft === 0) {
      setTimeLeft(20 * 24 * 60 * 60); // Reset timer after it finishes
    }
  }, [timeLeft]);

  const days = Math.floor(timeLeft / (24 * 60 * 60));
  const hours = Math.floor((timeLeft % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((timeLeft % (60 * 60)) / 60);
  const seconds = timeLeft % 60;

  const formatTime = (seconds) => {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    const secs = seconds % 60;
    return `${days}d ${hours}h ${minutes}m ${secs}s`;
  };

  const nextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % product.images.data.length);
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prev) =>
        (prev - 1 + product.images.data.length) % product.images.data.length
    );
  };

  return (
    <div className="max-w-7xl mx-auto p-4 md:p-8">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
        {/* Left Section - Images */}
        <div className="space-y-4">
          <div className="relative rounded-lg overflow-hidden shadow-lg">
            <img
              src={
                product.images?.data[currentImageIndex]?.attributes?.url || ""
              }
              alt={
                product.images?.data[currentImageIndex]?.attributes?.name ||
                "Product Image"
              }
              className="w-full h-auto object-contain" // Ensures the full image is displayed according to its width
              onError={(e) => {
                e.target.src =
                  "https://images.unsplash.com/photo-1505740420928-5e560c06d30e";
              }}
            />
            <button
              onClick={prevImage}
              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white/80 p-2 rounded-full shadow-md hover:bg-white"
              aria-label="Previous image"
            >
              <IoIosArrowBack size={24} />
            </button>
            <button
              onClick={nextImage}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white/80 p-2 rounded-full shadow-md hover:bg-white"
              aria-label="Next image"
            >
              <IoIosArrowForward size={24} />
            </button>
          </div>

          <div className="flex gap-2 overflow-x-auto py-2">
            {product.images?.data.map((img, index) => (
              <button
                key={index}
                onClick={() => setCurrentImageIndex(index)}
                className={`flex-shrink-0 ${
                  currentImageIndex === index ? "ring-2 ring-blue-500" : ""
                }`}
              >
                <img
                  src={img.attributes.url}
                  alt={`Product thumbnail ${index + 1}`}
                  className="w-20 h-20 object-cover rounded-lg"
                  onError={(e) => {
                    e.target.src =
                      "https://images.unsplash.com/photo-1505740420928-5e560c06d30e";
                  }}
                />
              </button>
            ))}
          </div>
        </div>

        {/* Middle Section - Product Details */}
        <div className="space-y-6">
          <h1 className="text-3xl font-bold">{product.title}</h1>
          <p className="text-xl font-semibold">
            <span className="text-gray-500">By</span>
            <span className="text-[#410AF3]"> {product.creator}</span>
          </p>
          <p className="text-xl text-gray-600">30% funded</p>
          <p className="text-2xl font-semibold text-[#410AF3]">
            ${product.price || "25.99"}
          </p>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-3 mb-6">
            {[
              { label: "Days", value: days },
              { label: "Hours", value: hours },
              { label: "Minutes", value: minutes },
              { label: "Seconds", value: seconds },
            ].map((item) => (
              <div
                key={item.label}
                className="flex flex-col items-center justify-center p-3 bg-gradient-to-b from-purple-50 to-indigo-50 rounded-lg transform transition-all duration-300 hover:scale-105"
                aria-label={`${item.value} ${item.label}`}
              >
                <div className="text-3xl md:text-4xl font-bold text-indigo-600 animate-pulse">
                  {String(item.value).padStart(2, "0")}
                </div>
                <div className="text-xs md:text-sm text-gray-600 font-medium mt-1">
                  {item.label}
                </div>
              </div>
            ))}
          </div>
          <button className="w-full bg-[#410AF3] text-white py-4 px-8 rounded-lg text-lg font-semibold hover:bg-blue-700 transition-colors duration-200 shadow-md">
            Pre-order Now
          </button>
          <div className="flex gap-2 border-b">
            {[
              { id: "creator", label: "Message from Creator" },
              { id: "shipping", label: "Shipping & Returns" },
              { id: "description", label: "Product Description" },
            ].map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`px-4 py-2 ${
                  activeTab === tab.id
                    ? "border-b-2 border-blue-500 text-blue-600"
                    : "text-gray-600 hover:text-blue-500"
                } transition-colors duration-200`}
                aria-selected={activeTab === tab.id}
                role="tab"
              >
                {tab.label}
              </button>
            ))}
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md min-h-[200px]">
            {activeTab === "creator" && (
              <p>{product.creator_message || "No message available"}</p>
            )}
            {activeTab === "description" && (
              <p>{product.description || "No description available"}</p>
            )}
            {activeTab === "shipping" && (
              <p>
                {product.shippingInfo || "No shipping information available"}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
