import { FaPhone } from "react-icons/fa";
import ProductPage from "../product/product";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom"; // Import useParams from react-router-dom
import { scrollToSection } from "../../components/Navbar";

export const Demo = () => {
  const { id } = useParams(); // Extract the `id` parameter from the route
  const [product, setProduct] = useState(null); // State to store product data
  const [loading, setLoading] = useState(true); // State to track loading

  // Fetch product data when the component mounts
  useEffect(() => {
    const fetchProduct = async () => {
      if (!id) return; // If no id is found, return early
      try {
        const response = await fetch(
          `https://api.plush.fun/api/products?populate[images][fields][0]=url&populate[images][fields][1]=name&filters[slug][$eq]=${id}`
        );
        const data = await response.json();
        setProduct(data.data[0]?.attributes); // Set the first product's attributes
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching product:", error);
        setLoading(false); // Set loading to false even in case of an error
      }
    };

    fetchProduct();
  }, [id]); // Depend on `id` so the effect runs when the `id` changes

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="spinner">Loading...</div>{" "}
        {/* Add a loading spinner or message */}
      </div>
    );
  }

  if (!product) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p>Error loading product. Please try again later.</p>
      </div>
    );
  }

  return (
    <>
      <section className="mt-24" id="home">
        <h1
          className="text-2xl lg:text-3xl font-bold text-gray-800 mb-6 text-center"
          aria-label="Commercial Proposal - Non-Binding"
        >
          <span className="font-bold">Commercial Proposal</span>{" "}
          <span className="font-normal">(Non-Binding)</span>
        </h1>

        <div className="max-w-6xl mx-auto px-4 flex flex-col gap-8 items-start">
          <h1 className="text-2xl">Hey {product.name},</h1>
          <ReactMarkdown>{product.email}</ReactMarkdown>
          <button
            className="mb-6 bg-[#410AF3] text-white px-6 py-3 rounded-lg flex items-center gap-2 transform transition-all duration-300 hover:bg-blue-700 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ml-auto"
            onClick={()=>scrollToSection('#contact')}
            aria-label="Schedule a call to discuss the proposal"
          >
            <FaPhone className="text-lg" />
            <span>Schedule Call</span>
          </button>
        </div>

        {/* Pass the product data to ProductPage */}
        <h1 className="max-w-6xl mx-auto text-4xl font-medium" id="demo">
          Below is a demo only, not a real crowdfunding campaign
        </h1>
        <div className="relative m-4 p-[6px] rounded-lg bg-gradient-custom">
          <div className="bg-white rounded-lg p-4">
            <ProductPage product={product} />
          </div>
        </div>

        <style jsx>{`
          .bg-gradient-custom {
            background: linear-gradient(
              67deg,
              #812be2 9%,
              #802ae4 17%,
              #ab40bd 32%,
              #c64ea6 60%,
              #d55f8c 81%,
              #de8b6f 100%
            );
          }
        `}</style>
      </section>
    </>
  );
};
